import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = ({ data }) => {
  const title = data.allContentfulContactUs.nodes[0].title
  const desc = data.allContentfulContactUs.nodes[0].seoDescription
  const intro =
    data.allContentfulContactUs.nodes[0].childContentfulContactUsIntroTextNode
      .childMarkdownRemark.html

  return (
    <Layout>
      <div className="px-6">
        <SEO title={title} description={desc} />
        <h1>{title}</h1>
      </div>

      <div className="md:flex md:flex-row-reverse w-full max-w-2xl mx-auto">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          className="md:w-3/5 px-6 my-6"
          data-netlify-honeypot="required-frm"
        >
          <div
            className="mx-auto form-intro"
            dangerouslySetInnerHTML={{
              __html: intro,
            }}
          />
          <input type="hidden" name="form-name" value="contact" />
          <p className="hidden">
            <label>
              req <input name="required-frm" />
            </label>
          </p>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:max-w-64 px-3">
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-name"
                name="name"
                type="text"
                placeholder="😇 Name"
                aria-label="Name"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <input
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-email"
                name="email"
                type="email"
                placeholder="📧 Email"
                aria-label="Email"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <textarea
                name="message"
                id="grid-message"
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-32"
                placeholder="✍️ Message"
                aria-label="Message"
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-700 text-black font-bold py-2 px-8 rounded"
          >
            <span role="img" aria-label="Send">
              Send 👌
            </span>
          </button>
        </form>
        <div className="md:flex md:max-w-xs px-6">
          <div className="my-6 md:text-right text-left">
            <h3>Telephone</h3>
            <p>
              <span itemProp="telephone">
                <a href="tel:+61412415000" title="Call Us">
                  +61 412 415 000
                </a>
              </span>
            </p>
            <h3>Social Media</h3>
            <p>
              <a
                href="https://facebook.com/trainingfourpaws"
                title="Go to facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              <br />
              <a
                href="https://instagram.com/trainingfourpaws"
                title="Go to Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulContactUs {
      nodes {
        seoDescription
        title
        childContentfulContactUsIntroTextNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default ContactPage
